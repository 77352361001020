// Variables
//----------------------------------
@font-face {
    font-family: 'lexend';
    src: url('font/Lexend-Light.ttf');
    font-weight: normal;
}
@font-face {
    font-family: 'lexend';
    src: url('font/Lexend-Medium.ttf');
    font-weight: bold;
}


@import 'variables';


// Plugins
//----------------------------------
@import "../../node_modules/bootstrap/scss/bootstrap";
@import '../../node_modules/font-awesome/scss/font-awesome.scss';
@import "../../node_modules/notie/src/notie";
@import "../../node_modules/toastr/toastr";
@import '../../node_modules/ladda/css/ladda.scss';
@import '../../node_modules/v-markdown-editor/dist/v-markdown-editor.css';
@import '../../node_modules/vue-multiselect/dist/vue-multiselect.min.css';
@import '../../node_modules/vue-tabs-component/docs/resources/tabs-component.css';

// Icons
//----------------------------------

//@import "../plugins/icons/icons.scss";


// Skins
//----------------------------------

@import 'skins/skin-stark';


// Site Base
//----------------------------------

@import 'base';


// Layouts
//----------------------------------

@import 'layouts/layout';


// Partials
//----------------------------------

@import 'partials/header';
@import 'partials/sidebar';


// Components
//----------------------------------

@import 'components/buttons';
@import 'components/forms';
@import 'components/cards';
@import 'components/tabs';
@import 'components/tables';
@import 'components/hamburgers';
@import 'components/transitions';
@import 'components/charts';
@import "components/ico-moon";
@import "components/easy-pie-chart";
@import "components/pace-loader";
@import "components/dropzone";

@import "components/vue-tabs";
@import "components/tooltips";
@import "components/vue-dropdown";
@import "components/gallery";
@import "components/modals";
@import "components/vue-date-picker";
@import "components/vue-color";
@import "components/vue-carousel";
@import "components/vue-multi-select";
@import "components/checklists";

@import '~pretty-checkbox/src/pretty-checkbox.scss';

// Pages
//----------------------------------

@import 'pages/dashboard';
@import 'pages/login';
@import 'pages/login-3';
@import 'pages/user-profile';
@import 'pages/404';
@import 'pages/settings';


// Apps
//----------------------------------


//Front
//----------------------------------

@import "front";


@import "pace-theme";
